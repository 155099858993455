/* Overall wrapper for the carousel */
.results-container-wrapper {
    position: relative;
    text-align: center;
    margin: 1rem auto 5rem;
    width: 80%;
    max-width: 600px;
  }
  
  /* Results container (the carousel) */
  .results-container {
    display: flex;
    overflow-x: scroll;
    gap: 10px;
    width: 100%;
    margin-top: 1rem;
    padding: 0 0px;
    scroll-snap-type: x mandatory; /* Ensures smooth scrolling behavior */
  }
  
  /* Each result card */
  .results-response {
    flex: 0 0 auto; /* Prevents the card from shrinking or growing */
    width: 100%; /* Adjusts the width dynamically to fit */
    max-width: 200px; /* Limits each card to a max-width */
    text-align: left;
    text-decoration: none;
    background-color: #fff;
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    scroll-snap-align: start; /* Ensures the card aligns correctly when scrolling */
    opacity: 1;
    position: relative; /* Required for positioning the circular image */
    cursor: pointer;
  }
  
  
  .results-response:hover {
    transform: translateY(5px); /* Hover effect to raise the card */
    background-color: #f9f4f2;
  }
  .result-image-container {
    width: 100%; /* Fixed width */
    height: 100px; /* Fixed height */
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    overflow: hidden; /* Ensures the image doesn't overflow the container */
    background-color: #f0f0f0; /* Optional: Add a background color for better visibility */
  }
  
  .result-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Result title styling */
  .result-title {
    font-size: 1rem;
    font-weight: bold;
    color: #6e574d;
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
  }
  
  .result-title:hover {
    text-decoration: underline;
  }
  
  /* URL styling */
  .result-url {
    font-size: 0.8rem;
    color: #78635a;
    display: block;
    margin-bottom: 10px;
    white-space: nowrap; /* Prevents wrapping */
    overflow: hidden;    /* Hides overflow text */
    text-overflow: ellipsis; /* Adds "..." at the end if the text overflows */
    display: block;      /* Ensures the element behaves as a block */
  }
  
  /* Snippet styling */
  .result-snippet {
    font-size: 0.8rem;
    color: #6e574d;
    line-height: 1.5;
    max-height: 80px;
    white-space: wrap;
    overflow: hidden;   
    text-overflow: ellipsis;
    display: block;     
  }

  .result-date {
    font-size: 0.8rem;
    color: #6e574d;
    line-height: 1.5;
    font-weight: 600;
    max-height: 80px;
    white-space: wrap;
    overflow: hidden;   
    text-overflow: ellipsis;
    display: block;     
  }
  
  /* Adjust scroll behavior for smaller screens */
  @media (max-width: 600px) {
    .results-container {
      gap: 10px;
      padding: 5px;
    }
  
    .results-response {
      max-width: 160px; /* Adjusts width for smaller screens */
    }

    .carousel-button {
        visibility: hidden;
    }
  }
  
  /* Carousel Controls (Buttons for scroll) */
  .left {
    position: absolute;
    left: -0px;
    top: 50%;
    width: 10px;
    display: flex;
    border-radius: 10px 0 0 10px;
    justify-content: space-between;
    transform: translateY(-50%);
    z-index: 2;
    height: 100%;
  }

  .right {
    position: absolute;
    right: -0px;
    transform: translateY(-100%);
    height: 100%;
    border-radius: 0 10px 10px 0;
  }
  
  .carousel-button {
    padding: 5px 10px;
    background-color: transparent;
    color: #6e574d;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    transition: 0.5s;
    height: 100%;
  }


  
  .carousel-button:hover {
    background-color: #6e574d2c;
  }
  
  .carousel-button:focus {
    outline: none;
  }
  
  