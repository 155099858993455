* {
  font-family: Poppins, sans-serif;
}

.about-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #6e574d87;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
}

.about-popup {
  background-color: #fff;
  padding: 5%;
  border-radius: 40px;
  width: 50%;
  max-width: 500px;
  max-height: 50%;
  text-align: center;
  position: relative;
  overflow-y: auto;
}

.about-popup-header h2 {
  font-size: 2rem;
  font-weight: bold;
  margin: 20px;
  color: #6e574d;
}

.about-popup-img {
  width: 50%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
}

.about-popup-description-container {
  text-align: center;
  padding: 0px 0;
}

.about-popup-description {
  font-size: 1rem;
  color: #6e574d;
  line-height: 1.5;
}

.about-popup-overlay .about-popup {
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.about-popup-close-btn {
  background-color: #6e574d;
  color: white;
  border: none;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 800;
  margin-top: 10px;
  position: absolute;
  top: 10px;
  right: 20px;
  transition: 0.5s ease;
}

.about-popup-close-btn:hover {
  background-color: #5a473f;
}

/* Responsive styles */
@media (max-width: 768px) {
  .about-popup {
    width: 80%;
    max-height: 70%;
  }

  .about-popup-header h2 {
    font-size: 1.5rem;
  }

  .about-popup-img {
    width: 60%;
  }

  .about-popup-description {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .about-popup {
    width: 90%;
    max-height: 80%;
    padding: 8% 10%;
  }

  .about-popup-header h2 {
    font-size: 2rem;
  }

  .about-popup-img {
    width: 70%;
  }

  .about-popup-description {
    font-size: 1rem;
    line-height: 1.5;
  }

  .about-popup-close-btn {
    font-size: 0.9rem;
  }
}
