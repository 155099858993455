  * {
    font-family: Poppins , sans-serif;
  }
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(110, 87, 77, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup {
    background-color: #ffffff;
    position: relative;
    padding: 50px;
    width: 300px;
    max-width: 90%;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .popup input::placeholder {
    color: #6e574d7d;
}
  
  .popup h2 {
    margin-top: 0;
    color: #6e574d;
  }

  .popup-text {
    font-size: 1rem;
    color: #6e574d;
  }
  
  .popup form {
    display: flex;
    flex-direction: column;
  }
  
  .popup input {
    margin: 10px 0;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    color: #6e574d;
    border-radius: 30px;
    background-color: #f5f0ee49;
    width: 100%
  }

  .popup textarea {
    margin: 10px 0;
    padding: 10px 20px;
    height: 300px;
    box-sizing: border-box;
    border: none;
    color: #6e574d;
    border-radius: 30px;
    background-color: #f5f0ee49;
    width: 100%
  }

  .popup textarea::placeholder {
    color: #6e574d7d;
}

  .popup input:focus {
    outline: none;
    background-color: #f5f0ee49;
  }

  .popup input:active {
    outline: none;
    background-color: white;
  }
  
  .popup-btn {
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    font-weight: 600;
    background-color: #6e574d;
    color: #fff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
  }

  .popup-cancel-btn {
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    font-weight: 600;
    background-color: transparent;
    color: #5b4a41;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: 0.3s;
  }

  .popup-cancel-btn:hover {
    color: #5b4a417d
  }
  
  .popup-btn:hover {
    background-color: #5b4a41;
  }
  
  .popup-footer {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #6e574d;
  }
  
  .link {
    color: #6e574d;
    font-weight: 500;
    cursor: pointer;
  }
  
  .close-btn {
    background-color: #6e574d;
    color: white;
    border: none;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 800;
    margin-top: 10px;
    position: absolute;
    top: 10px;
    right: 20px;
    transition: 0.5s ease;
  }
  
  .error-message {
    color: goldenrod;
    font-size: 0.9rem;
  }