.header {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  justify-content: flex-end;
  padding: 20px;
  background-color: transparent;
  border-bottom: none;
}

.sign-in-btn {
  padding: 10px 30px;
  margin: 10px;
  background-color: transparent;
  color: white;
  font-size: 0.8rem;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.sign-in-btn:hover {
  background-color: #72564b;
}

.hamburger-menu-icon {
  display: none;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.mobile-menu-item {
  padding: 20px 20px;
  font-size: 1rem;
  width: 60%;
  max-width: 300px;
  font-weight: 400;
  background-color: transparent;
  color: #6e574d;
  border: none;
  cursor: pointer;
  margin: 0px 0;
  /*border-bottom: 1px solid #6e574d2e;*/
  transition: 0.3s;
}

.mobile-menu-item:hover {
  background-color: #72564b;
  color: white;
  font-weight: 600;
}
.close-menu-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-icon {
  font-size: 24px;
  color: #6e574d;
}

.close-menu-btn:hover .close-icon {
  color: #6e574d;
}

@media (max-width: 1400px) {
  .sign-in-btn {
    display: none;
  }

  .hamburger-menu-icon {
    display: block;
    color: white;
  }
  
}

.menu-img {
  width: 60%;
  max-width: 300px;
}

