* {
  font-family: Poppins , sans-serif;
}

.homepage {
  padding-top: 50px;
  text-align: center;
}

.home-title {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 600px;
  margin: 200px auto 0;
  padding: 0 0px;
  transition: margin-top 0.3s ease;
}

.search-container.move-to-top {
  margin-top: 20px;
}

.search-container.move-to-top .search-input {
  background-color: #c5b4ad;
  transition: 0.5s ease;
}

.search-container.move-to-top .search-input:focus {
  background-color: #fff;
  color: #6e574d;
}

.search-container.move-to-top .search-input:hover {
  background-color: #fff;
  color: #6e574d;
}

.search-input-container {
  width: 100%;
  position: relative;
}

.search-input {
  width: 100%;
  border-radius: 50px;
  padding: 25px 70px 25px 30px;
  border: none;
  box-sizing: border-box;
  color: #6e574d;
  font-size: 1rem;
  font-family: Poppins, sans-serif;
  margin-bottom: 1rem;
}

/* Search input container animation when results are displayed */
.search-container.move-to-top {
  margin-top: 0px;
  padding: 0px 40px;
  transition: margin-top 0.3s ease-in-out;
}

/* Shrinking the header image when search results are shown */
.headerimg.shrink {
  transform: scale(0.7);
  height: auto;
  transition: width 0.3s ease, opacity 0.3s ease;
  opacity: 0.8;
}

/* Search icon and search input container */
.search-input-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}


.search-input::placeholder {
  color: #6e574d7d;
}

.search-input:focus,
.search-input:active {
  border-color: #6e574d;
  box-shadow: 0 0 0 2px rgba(110, 87, 77, 0.25);
  outline: none;
}

.search-icon {
  position: absolute;
  right: 30px;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;
}

.error {
  color: goldenrod;
  margin: 1rem 0;
}

.response {
  position: relative;
  justify-content: center;
  text-align: center;
  font-size: 0.85rem;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 30px 50px 50px 50px;
  line-height: 1.6;
  background-color: white;
  color: #6e574d;
  border-radius: 20px;
  transition: 0.5s;
  box-shadow: 0px 4px 10px #231a1a7d;
}

.response:hover {
  transform: scale(1.05);
}

.response-container {
  width: 100%;
}

.response-body {
  text-align: left;
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
  color: #6e574d;
  transition: background-color 0.5s;
}

.save-button {
  position: absolute;
  background-color: #c5b4ad49;
  color: #6e574d;
  border-radius: 10px;
  padding: 5px 10px;
  bottom: 20px;
  right: 20px;
  text-align: right;
  justify-content: right;
  border: none;
  transition: 0.3s;
  cursor: pointer;
}

.save-button:hover {
  color: #5b4a41;
  background-color: white;
  border: solid 1px #c5b4ad;
}

.save-icon {
  margin-left: 5px;
}

.fade-out {
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.headerimg {
  transition: transform 0.5s ease, opacity 0.5s ease; 
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
}

.headerimg:hover {
  transform: scale(1.1);
  opacity: 0.9;
}

.response-skeleton {
  width: 100%;
  height: 100px;
  max-width: 600px;
  border-radius: 20px;
  background-color: #e0e0e0;
  animation: pulse 1.5s ease-in-out infinite;
}

.link-skeleton {
  width: 80%;
  height: 100px;
  border-radius: 20px;
  background-color: #e0e0e0;
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    background-color: #e4d8d3;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    background-color: #e4d8d3;
  }
}


/*-----------------------------Responsive-----------------------------------------*/
@media (max-width: 768px) {
  .home-title {
      font-size: 2rem;
  }

  .search-input {
      font-size: 0.9rem;
      width: 100%;
  }

  .response-body {
      font-size: 1.1rem;
  }
  
}

@media (max-width: 480px) {
  .home-title {
      font-size: 1.8rem;
  }

  .search-input {
      padding: 15px 50px 15px 20px;
      width: 100%;
  }

  .search-icon {
    position: absolute;
    right: 20px;
    top: 25px;
    cursor: pointer;
    width: 25px;
  }

  .search-container.move-to-top {
    margin-top: 20px; 
  }

  .response-body {
    font-size: 0.9rem;
}
}

