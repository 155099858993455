.add-note-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(110, 87, 77, 0.3);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 1000;
  }
  
  .add-note-popup {
    background-color:transparent;
    background-image: url(../assets/notepad.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 30px;
    left: 30px;
    height: fit-content;
    padding: 85px 60px 20px 60px;
    width: 270px;
    max-width: 90%;
    border-radius: 30px;
    text-align: center;
    animation: slideIn 0.5s ease-out;
  }
  
  /* Animation for smooth entry */
  @keyframes slideIn {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .add-note-popup h2 {
    margin-top: 0px;
    font-size: 0.8rem;
    color:#6e574d;
  } 

  .add-note-popup input {
    margin: 10px 0 0 0;
    padding: 10px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 1.2rem;
    border: none;
    color: #6e574d;
    border-radius: 10px;
    background-color: transparent;
    width: calc(100% - 10px);
    outline: none;
  }

  .add-note-popup input::placeholder {
    color: #6e574d7d;
  }

  .add-note-popup textarea {
    margin: 0 0 20px 0;
    padding: 10px;
    height: 100px; /* Set a consistent height */
    width: calc(100% - 10px); /* Ensure it fits within the container, considering padding */
    box-sizing: border-box; /* Ensure padding is included in total width/height */
    font-size: 1rem;
    font-weight: 400;
    color: #6e574d;
    border: none;
    border-radius: 10px;
    background-color: transparent;
    outline: none;
    resize: none; /* Prevent resizing by user */
    overflow-wrap: break-word; /* Prevent text from going beyond textarea width */
  }
  
  .add-note-popup textarea::placeholder {
    color: #6e574d7d;
  }

  .add-note-btn {
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    font-weight: 600;
    background-color: goldenrod;
    color: #fff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: 0.3s;
  }

  .add-note-btn:hover {
    background-color: rgb(188, 146, 40);
  }

 .add-note-close-btn {
    background-color: #6e574d;
    color: white;
    border: none;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 800;
    margin: 5px 0;
    position: absolute;
    top: 10px;
    left: 20px;
    transition: 0.5s ease;
 }

 @media (max-width: 768px) {
  .add-note-popup {
    left: 50%;
    top: 20px;
    box-shadow: none;
    transform: translateX(-50%);
    animation: slideIn 0.5s ease-out;
  }

  .add-note-popup textarea {
    margin: 0 0 20px 0;
    padding: 10px;
    height: 50px;
  }
  
}