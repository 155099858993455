* {
  font-family: Poppins, sans-serif;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(73, 58, 51, 0.511);
  z-index: 999;
  cursor: pointer;
  transition: background-color 1s ease;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background-color: #e9e3e0;
  color: #6e574d;
  box-shadow: 0 0 2px 2px rgba(110, 87, 77, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1000;
}

.sidebar.open {
  transform: translateX(0);
}

/* Rest of the styles remain the same... */


.sidebar h2 {
  margin-top: 0;
  font-size: 0.9rem;
  text-align: left;
  font-weight: 500;
  color: #6e574d82;
}

.search-history,
.notes-section {
  display: flex;
  flex-direction: column;
}

.search-card {
  background-color: #fff;
  position: relative;
  height: 100px;
  font-size: 0.9rem;
  border-radius: 10px;
  padding: 20px 30px 0 30px;
  margin-bottom: 10px;
  box-shadow: 2px 2px 0 0 rgba(110, 87, 77, 0.1);
  cursor: pointer;
  transition: 0.5s ease;
}

.search-card:hover {
  background-color: #ffffff87;
}

.search-card h3 {
  margin: 0;
  font-size: 0.85rem;
  font-weight: 500;
}

.search-card p {
  margin: 5px 0 0 0;
  font-size: 0.85rem;
}

.note-card {
  background-color: rgb(255, 252, 223);
  position: relative;
  height: 100px;
  font-size: 0.9rem;
  border-radius: 10px;
  padding: 20px 30px 0 30px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.5s ease;
  box-shadow: 2px 2px 0 0 rgba(110, 87, 77, 0.1);
}

.note-card:hover {
  background-color: #fff6a3;
}

.note-card h3 {
  margin: 0;
  font-size: 0.85rem;
  font-weight: 500;
}

.note-card p {
  margin: 5px 0 0 0;
  font-size: 0.85rem;
}

.sidebar.open + .overlay {
  background-color: rgba(110, 87, 77, 0.3);
}

.icon-bottom-right{
  position: absolute;
  bottom: 15px;
  right: 15px;
  margin-bottom: 1rem;
  font-size: 1rem;
  color: goldenrod;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  }
  
.icon-bottom-right:hover {
  color: goldenrod;
}

.edit-profile-link {
  font-size: 0.8rem;
  color: #6e574d;
}

.greeting {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 10px 0 0;
}

.sidebar-header {
  margin-bottom: 20px;
}

/* Refresh button */
.refresh-button {
  background: transparent;
  border: none;
  color: #6e574d;
  cursor: pointer;
  font-size: 1rem;
  padding: 5px;
  position: absolute;
  top: 10px;
  right: 20px;
}

.refresh-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.note-title {
  white-space: nowrap; /* Prevent the title from wrapping to the next line */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Add "..." at the end if the title is too long */
  max-width: 100%; /* Adjust based on container's width */
}

.loading-message {
  font-size: 0.8rem;
  margin-left: 20px;
}

.timestamp {
  font-size: 0.6rem;
  text-align: left;
}

.notes-timestamp {
  font-weight: 300;
}

.modal-title.editing:focus, .modal-content-text.editing:focus {
  outline: none;
  color: #7f6559;
}

.action-button {
  background-color: #c5b4ad49;
  color: #6e574d;
  font-weight: 500;
  border-radius: 10px;
  padding: 5px 10px;
  top: 20px;
  right: 20px;
  text-align: right;
  justify-content: right;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  margin-right: 10px;
}

.action-button:hover {
  color: #5b4a41;
  background-color: white;
  border: solid 1px #c5b4ad;
}

.edit-modal {
  background-color: #fff;
  color: #6e574d;
  padding: 50px;
  min-width: 300px;
  min-height: 300px;
  width: 40%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.label-cell {
  width: fit-content;
  height: 40px;
  font-weight: 600;
  font-size: 1rem;
}

.info-cell {
  padding-left: 30px;
  width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

input.edit-user-details{
  width: 100%;
  padding: 10px;
  height: 20px;
  border: none;
  color: #6e574d;
  background-color: #6e574d1e;
}

input.edit-user-details:focus {
  outline: none;
}

.button-cell {
  padding-left: 50px;
}

.terms-link {
  text-decoration: none;
  font-size: 0.8rem;
  color: #6e574d;
}

.edit-button {
  background-color: #c5b4ad49;
  color: #6e574d;
  border-radius: 10px;
  padding: 5px 10px;
  top: 20px;
  right: 20px;
  text-align: right;
  justify-content: right;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  margin-right: 10px;
}

.terms {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 20px;
}

.success-message {
  color: goldenrod;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 14px;
  color: #666;
  text-align: center;
}

.loading-spinner::after {
  content: "";
  width: 24px;
  height: 24px;
  border: 4px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  display: inline-block;
  margin-left: 8px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


/*-------------------------------- Modal ----------------------------------*/

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(110, 87, 77, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #fff;
  color: #6e574d;
  padding: 50px;
  min-width: 300px;
  min-height: 300px;
  width: 40%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-content {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 10px;
}

.search-modal {
  background-color: #fff;
  color: #6e574d;
  position: relative;
}

.note-modal {
  background-color: #fffcdf;
  color: #6e574d;
}

.modal-title {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
}

.modal p {
  font-size: 16px;
  line-height: 1.5;
}

.close-modal {
    background-color: #6e574d;
    color: white;
    border: none;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 800;
    margin-top: 10px;
    position: absolute;
    top: 10px;
    right: 20px;
    transition: 0.5s ease;
  }


.close-modal:hover {
  color: #fff;
}


.modal-overlay {
  display: flex;
}

@media (max-width: 1150px) {
  .label-cell {
    display: none;
  }

  .info-cell {
    padding: 0;
    height: 50px;
  }

  td.info-cell {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (max-width: 768px) {
  .edit-button {
    text-align: left;
    width: 150px;
  }

}